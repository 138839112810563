:root {
  --saa-primary-color: #25282b;
  --saa-primary-color-80: #52575c;
  --saa-primary-color-60: #a0a4a8;
  --saa-primary-color-40: #cacccf;
  --saa-primary-color-20: #dbdde0;
  --saa-primary-color-10: #e8e8e8;
  --saa-primary-color-5: #f9f9fa;
  --saa-white-color: #ffffff;
  --saa-blue-20: #cfdbe6;
  --saa-blue-40: #9fb7cd;
  --saa-blue-50: #87a5c0;
  --saa-blue-60: #6f94b3;
  --saa-blue-90: #0f4c81;

  --saa-error: #fb4e4e;
  --saa-error-light: #ff6262;
  --saa-error-dark: #e93c3c;
  --saa-warning: #f6a609;
  --saa-warning-light: #ffbc1f;
  --saa-warning-dark: #e89806;
  --saa-success: #2ac769;
  --saa-success-light: #40dd7f;
  --saa-success-dark: #1ab759;
  --saa-info: #3e91f9;
  --saa-note: #ffff88;
  --saa-note-dark: #dddd51;
  --saa-auditor-note: #f2e6ff;
  --saa-auditor-note-dark: #e7cfff;
  --saa-auditor-note-follow-up: #e6f7ff;
  --saa-auditor-note-follow-up-dark: #b3e0f2;
  --saa-brand-note: #a5fda6;
  --saa-brand-note-dark: #98ec99;
  --saa-brand-note-light: #ffff88;
  --saa-brand-note-light-violet: #f2e6ff;
  --saa-brand-note-light-green: #a5fda6;
  --saa-regional-manager-note: #fdd7a0;
  --saa-regional-manager-note-dark: #ecc895;

  --saa-assessment-in-progress: #6f94b3;
  --saa-assessment-merging: #0f4c81;
  --saa-assessment-under-review-by-regional-manager: #dad754;
  --saa-assessment-ready-to-review: #ffbc1f;
  --saa-assessment-under-review: #ff751f;
  --saa-assessment-approved-to-be-sent: #40dd7f;
  --saa-assessment-sent-to-the-stores: #2ac7c4;
  --saa-assessment-actions-plans-under-review: #ab34a9;
  --saa-assessment-closed: #52575c;

  --saa-priority-low: #5ac66c;
  --saa-priority-medium: #ffff75;
  --saa-priority-significant: #ffc000;
  --saa-priority-high: #ff5050;

  --saa-audit-proposal-draft: #6f94b3;
  --saa-audit-proposal-proposed: #0f4c81;
  --saa-audit-proposal-reproposed-by-brand: #2ac7c4;
  --saa-audit-proposal-reproposed-by-auditor-scheduler: #ab34a9;
  --saa-audit-proposal-rejected-by-brand: #ff751f;
  --saa-audit-proposal-rejected-by-auditor-manager: #ffbc1f;
  --saa-audit-proposal-to-be-approved: #3bd399;
  --saa-audit-proposal-approved-by-auditor-manager: #40dd7f;
  --saa-audit-proposal-scheduled: #1ab759;
  --saa-audit-proposal-closed: #52575c;
  --saa-audit-proposal-deleted: #fb4e4e;

}
