#root i.icon {
  font-family: Icons, 'Font Awesome 5 Free';
}

body i.icon {
  font-family: Icons, 'Font Awesome 5 Free';
}

i.icon {
  height: auto;
}

#root .ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: var(--saa-primary-color) !important;
}

#root .ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  background-color: var(--saa-primary-color-10);
}

/* calendar */
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: var(--saa-primary-color) !important;
}

.react-calendar__tile--now {
  background-color: var(--saa-blue-40) !important;
}

.react-calendar * {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  text-transform: capitalize;
}

.react-calendar__tile--hasActive {
  background-color: var(--saa-primary-color) !important;
  color: var(--saa-white-color);
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--saa-primary-color-10) !important;
}

.ui.modal .actions .ui.primary.button {
  color: var(--saa-white-color);
  background-color: var(--saa-primary-color);
}

.ui.modal .actions .ui.primary.button:hover {
  color: var(--saa-primary-color-60);
  background-color: var(--saa-primary-color);
}

.ui.modal .actions .ui.button {
  background-color: var(--saa-primary-color-20);
  color: var(--saa-primary-color);
}

.ui.modal .actions .ui.button:hover {
  color: var(--saa-primary-color-60);
  background-color: var(--saa-primary-color-20);
}

/* range calendar */

.rmdp-wrapper .rmdp-calendar .rmdp-ym .rmdp-range-hover.start, .rmdp-ym .rmdp-range.start {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.rmdp-wrapper .rmdp-calendar .rmdp-ym .rmdp-range-hover.end, .rmdp-ym .rmdp-range.end {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.rmdp-wrapper .rmdp-calendar .rmdp-ym .rmdp-day span{
  border-radius: 0;
}

.rmdp-wrapper .rmdp-calendar {
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.rmdp-wrapper.rmdp-shadow {
  box-shadow: 0 0 1px var(--saa-primary-color-80);
}

.rmdp-wrapper .rmdp-calendar .rmdp-panel-body li {
  background-color: var(--saa-primary-color);
  box-shadow: 0 0 2px var(--saa-primary-color-60);
}

.rmdp-wrapper .rmdp-calendar .rmdp-week-day {
  color: var(--saa-primary-color);
}

.rmdp-wrapper .rmdp-calendar .rmdp-day.rmdp-deactive {
  color: var(--saa-primary-color-60);
}

.rmdp-wrapper .rmdp-calendar .rmdp-range {
  background-color: var(--saa-primary-color);
  box-shadow: 0 0 3px var(--saa-primary-color-40);
}

.rmdp-wrapper .rmdp-calendar .rmdp-arrow {
  border: solid var(--saa-primary-color);
  border-width: 0 2px 2px 0;
}

.rmdp-wrapper .rmdp-calendar .rmdp-arrow-container:hover {
  background-color: var(--saa-primary-color);
  box-shadow: 0 0 3px var(--saa-primary-color-60);
}

.rmdp-wrapper .rmdp-calendar .rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--saa-primary-color);
}

.rmdp-wrapper .rmdp-calendar .rmdp-day.rmdp-today span {
  background-color: var(--saa-primary-color-10);
}

.rmdp-wrapper .rmdp-calendar .rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--saa-primary-color-60);
}

.rmdp-wrapper .rmdp-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--saa-primary-color);
  box-shadow: 0 0 3px var(--saa-primary-color-40);
}

.rmdp-wrapper .rmdp-calendar .rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: var(--saa-primary-color-80) !important;
  border-radius: 0;
}

.rmdp-wrapper .rmdp-calendar .b-deselect {
  color: var(--saa-primary-color-20);
  background-color: white;
}

.rmdp-wrapper .rmdp-calendar .rmdp-action-button {
  color: var(--saa-primary-color);
}

.rmdp-wrapper .rmdp-calendar .rmdp-button:not(.rmdp-action-button) {
  background-color: var(--saa-primary-color);
}

.rmdp-wrapper .rmdp-calendar .rmdp-button:not(.rmdp-action-button):hover {
  background-color: var(--saa-primary-color-60);
}
