.success-toast {
  position: relative;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
  margin-top: 5px;
  background: var(--saa-success);
  border-radius: 15px 0 0 15px;
  min-height: 60px;
}

@media (min-width: 480px) {
  .success-toast {
    width: 120px;
  }
}

.success-toast-body {
  display: flex;
  flex: 0;
}
